import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import tw, { styled } from "twin.macro";
import { StaticImage } from "gatsby-plugin-image";
import { MAIN_COLOR, SITE } from "../config";

const StyledLink = styled(Link)`
  ${tw`bg-[#9561a9] text-white px-4 py-1 rounded hover:opacity-70`}
`;
const Container = styled.div`
  ${tw`px-5 mt-[100px] flex flex-col-reverse sm:flex-row justify-center items-center`}
`;
const TextSection = styled.div`
  ${tw`p-2`}
`;
const Header = styled.h1`
  ${tw`text-9xl font-bold text-center`}
`;
const ImageContainer = styled.div`
  ${tw`flex justify-center`}
`;
const SubHeader = styled.h4`
  ${tw`text-3xl font-bold text-[#9561a9] mt-2 text-center`}
`;
const Paragraph = styled.p`
  ${tw`mt-2 text-center text-gray-700`}
`;
const LinkContainer = styled.div`
  ${tw`flex justify-center my-6`}
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <Container>
        <TextSection>
          <Header color={MAIN_COLOR} site={SITE}>
            404
          </Header>
          <SubHeader>Page Not Found</SubHeader>
          <Paragraph>We can't find the page you're looking for...</Paragraph>
        </TextSection>
        <ImageContainer>
          <StaticImage
            src="../../static/img/404.png"
            alt="404"
            placeholder="tracedSVG"
          />
        </ImageContainer>
      </Container>
      <LinkContainer>
        <StyledLink to="/">Return Home</StyledLink>
      </LinkContainer>
    </Layout>
  );
};

export const Head = () => <Seo title="404: Not Found" />;

export default NotFoundPage;
